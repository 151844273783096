<template>
  <div class="file-list">
    <div class="content-file">
        <p>
          <span>{{title}}</span>
        </p>
        <!-- <template v-if="fileList.length > 0">
          <a :href="baseURL+v" target="_blank"
              v-for="(v,i) in fileList" :key="i" class="file_list">
            <span>{{ v ? v.substring(v.lastIndexOf('/') + 1) : '无附件' }}</span>
            <span>
              <img src="@/assets/images/download/ic_xz@2x.png"/>
              <el-button type="text">下载</el-button>
            </span>
          </a>
        </template>
        <a v-else class="file_list" style="justify-content: center;">无附件</a> -->
        <ul class="list-data">
          <template v-if="fileList.length > 0">
            <li v-for="item of fileList" :key="item.id">
              <div class="title">
                <h3>
                  <a :href="baseURL+item" target="_blank">
                  {{ item ? item.substring(item.lastIndexOf('/') + 1) : '无附件' }}
                  </a>
                </h3>
                <div class="date">
                  <a :href="baseURL+item" target="_blank">
                    下载
                  </a>
                  </div>
              </div>
            </li>
          </template>
          <div class="no-data" v-else>
            <img src="@/assets/images/new-ui/gy_pic_none@2x.png" alt="">
            <div>暂无内容</div>
          </div>
        </ul>
      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: '公告附件'
    }
  },
  computed: {
    ...mapState(["baseURL"])
  },
}
</script>

<style lang="less" scoped>
  .file-list {
    .content-file {
        line-height: 20px;

        p {
          line-height: 48px;
          background: #F4F4F4;
          font-size: 18px;
          font-weight: bold;
          color: #20201E;
          padding-left: 15px;
          margin-top: 35px;
        }

        .file_list {
          border-bottom: 1px dashed rgba(236, 236, 241, 1);
          line-height: 68px;
          display: flex;
          justify-content: space-between;
          color: rgba(32, 32, 30, 1);

          img {
            width: 24px;
            margin-right: 4px;
          }
        }

      }
      .list-data {
      margin-top: 16px;
      >li {
        padding-left: 14px;
        padding-bottom: 24px;
        margin-top: 24px;
        position: relative;
        border-bottom: 1px dashed #ECECF1;
        &::after {
          position: absolute;
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background-color: #F36E31;
          left: 0;
          top: 9px;
        }
        .title {
          display: flex;
          align-items: center;
          >h3 {
            flex: 1;
            color: #20201E;
            font-size: 16px;
            cursor: pointer;
            transition: color 0.2s;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 10px;
            &:hover {
              color: #F36E31;
            }
          }
          >div:nth-child(1) {
            background-color: #FFF0E8;
            font-size: 14px;
            color: #F36E31;
            height: 23px;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #FFE0CF;
            display: flex;
            align-items: center;
            padding: 0 10px;
            margin-right: 8px;
          }
          .date {
            color: #F36E31;
            font-size: 16px;
            cursor: pointer;
            &:hover {
              color: #D44053;
            }
          }
        }
        .time {
          display: flex;
          margin-top: 12px;
          color: #999;
          font-size: 14px;
          .end-time {
            border-radius: 4px;
            background-color: #F4F4F4;
            height: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            margin-right: 12px;
          }
          .countdown {
            display: flex;
            align-items: center;
            .red {
              color: #FA5151;
              font-weight: bold;
              margin-left: 4px;
            }
            .nored {
              margin-left: 4px;
              color: #999;
              font-weight: bold;
            }
          }
        }
      }
    }
    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #C3C3C3;
      margin-bottom: 32px;
    }
  }
</style>