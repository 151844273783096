/*
  招采信息相关接口
*/

import req from '@/utils/request' //引入暴露出来的请求函数

export default {
  // 获取招采信息导航
  findTradeNav: params => req.get('/api/transaction/tranNavigation', params),
  // 获取招采信息列表
  findTradeList: params => req.get('/api/transaction/findTransactionList', params),
  // 获取招采信息详情
  findTradeDetail: params => req.get('/api/transaction/detail', params),
  // 招財信息詳情左側的公告列表
  findTradeDetailAnnouncement: params => req.get('/api/transaction/relative-notice-list', params),
}
